import styled from 'styled-components';
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';


    export const Nav = styled.nav`
    background:${({scrollNav}) => (scrollNav ? '#000':'transparent')};
    height:70px;
    margin-top: -70px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:1rem;
    position:sticky;
    top:0;
    z-index:10;
    @media screen and(max-width:960px) {
        transitions: 0.8 all ease;
    }
    `;
    export const NavbarContainer = styled.div`
    display:flex;
    justify-content:space-between;
    height:70px;
    z-index:1;
    width: 100%;
    padding:0 22px;
    max-width:1100px;
    `;
    export const NavLogo = styled(LinkR)`
    color: pink;
    justify-self:flex-start;
    cursor:pointer;
    font-size: 1.5rem;
    display: flex;
    align-items:center;
    margin-left:2px;
    font-weight: bold;
    text-decoration:none;
    `;
    export const MobileIcon = styled.div`
    display:none;
    
    @media screen and (max-width:767px){
        display:block;
        position:absolute;
        top:0;
        right:0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor:pointer;
        color: #fff;

    }`;

export const NavMenu = styled.ul`
display:flex;
align-items:center;
list-stye: none;
text-align:center;
margin-right: -20px;
@media screen and (max-width:767px)
{display:none;
};
`;
export const NavItem = styled.li`
height: 80px;
`
// export const NavLinks = styled(LinkS)`
// color: #fff;
// display: flex;
// align-items: center;
// text-decoration: none;
// padding: 0 1rem;
// height: 100%;
// cursor:pointer;

// &.active{
//     border-bottom: 3px solid #fff;
// }
// `;
export const NavIcon = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
width: 60px;




`
export const NavIconLinks =styled.a`
color:#fff;
font-size:24px;






`

export const NavBtn = styled.nav`
display:flex;
align-items:center;

@media screen and (max-width:767px){
    display:none;


};
`;
export const NavBtnLink = styled(LinkS)`
border-radius: 50px;
background:pink;
white-space:nowrap;
padding:10px 18px;
color:#010606;
font-size:16px;
outline:none;
border:none;
cursor:pointer;
transition: all 0.2s ease-in-out;
text-decoration:none;

&:hover{
    transition:all 0.2s ease-in-out;
    background:#fff;
    color: #010606;
};


 `;