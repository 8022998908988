import React, { useEffect, useState, useRef } from "react";
import Video from '../../videos/pink4.mp4';
import { HeroContainer,HeroBg,ImageBg,VideoBg, HeroContent,HeroBtnWrapper,ArrowForward,ArrowRight} from './HeroElements';
import {Button} from '../ButtonElements';
import Pink from '../../videos/pfp.mp4';







const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover =()=>{ setHover(!hover)
    };
   

    const [isMobile,setMobile] = useState(window.innerWidth);
    const IV = isMobile < 870 ? Pink: Video;

    useEffect(() => {
        const handleWindowResize = () => {
            setMobile(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);


//     const setMobileHero =()=>{
//         if(window.innerWidth < 870){
//             setMobile(true);
//         }else { setMobile(false);
//         }
//     };

//    useEffect(() => {window.addEventListener ('resize',setMobileHero);
//    return () => {            
//     window.removeEventListener('resize', setMobileHero);      
//   };
  
 
    
//     }, []);



    return (
        <HeroContainer >
            <HeroBg>
            <ImageBg autoPlay loop muted src={IV} type='video/mp4' />
            </HeroBg>
          
       </HeroContainer>
      
    )
};




export default HeroSection
