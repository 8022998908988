import React ,{useState, useEffect} from 'react'
import {FaBars, FaInstagram, FaTwitter, FaDiscord, FaShoppingCart} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import {animateScroll as scroll} from 'react-scroll'
import {Nav,NavbarContainer,NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, NavIcon, NavIconLinks} from './NavbarElements'

const Navbar = ({toggle}) => {
    const [scrollNav,setScrollNav] = useState(false);

    const changeNav =()=>{
        if(window.scrollY>= 80){
            setScrollNav(true);

        }else { setScrollNav(false);
        }
    };
    useEffect(() => {window.addEventListener ('scroll', changeNav);


    }, []);

    const toggleHome =()=>{
        scroll.scrollToTop();
    };



    // const toggleMint =()=>{
    //     scroll.scrollTo(mint);
    // }


    return (
      <>
      <IconContext.Provider value={{color:'#fff'}}>
      <Nav scrollNav={scrollNav}>
          <NavbarContainer>
              <NavLogo to ='/' onClick={toggleHome}>Pink Cat</NavLogo>
              <MobileIcon onClick={toggle}>
                  <FaBars/>
              </MobileIcon>
              <NavMenu>
              <NavIcon>
                  <NavIconLinks href='//www.instagram.com/pinkcatdaily/?hl=en' target="_blank" aria-label="Instagram">
                      <FaInstagram/>
                  </NavIconLinks>
              </NavIcon>
              <NavIcon>
                  <NavIconLinks href='//www.twitter.com/PINKCATNFT?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target="_blank" aria-label="Twitter">
                      <FaTwitter/>
                  </NavIconLinks>
              </NavIcon>
              <NavIcon>
                  <NavIconLinks href='//www.discord.com/invite/zkAxnGjJtV' target="_blank" aria-label="Discord">
                      <FaDiscord/>
                  </NavIconLinks>
              </NavIcon>
              <NavIcon>
                  <NavIconLinks href='//www.pinkcatflowers.com/' target="_blank" aria-label="Shop">
                      <FaShoppingCart/>
                  </NavIconLinks>
              </NavIcon>


              </NavMenu>

              <NavBtn>
                  <NavBtnLink to='mint' smooth={true} duration={500} spy={true} exact='true' offset={-70} >Mint Page</NavBtnLink>
              </NavBtn>

          </NavbarContainer>
      </Nav>
      </IconContext.Provider>
      </>
    )
}

export default Navbar
