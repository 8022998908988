import React from 'react'
import { SidebarContainer,Icon,CloseIcon,SidebarLink,SidebarRoute,SidebarWrapper, SideBtnWrap, SidebarMenu, SBIconLinks, SBIcon} from './SidebarElements'
import {FaBars, FaInstagram, FaTwitter, FaDiscord, FaShoppingCart} from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll'

const Sidebar = ({isOpen,toggle}) => {

    // const toggleDown =()=>{
    //     scroll.scrollToBottom();
    // }
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                 <SidebarLink href='//www.instagram.com/pinkcatdaily/?hl=en' target="_blank" aria-label="Instagram">
                 <FaInstagram/>
                    </SidebarLink>
                    <SidebarLink href='//www.twitter.com/PINKCATDAILY?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target="_blank" aria-label="Twitter">
                 <FaTwitter/>
                    </SidebarLink>
                    <SidebarLink href='//www.discord.com/invite/zkAxnGjJtV' target="_blank" aria-label="Discord">
                 <FaDiscord/>
                    </SidebarLink>
                    <SidebarLink href='//www.pinkcatflowers.com/' target="_blank" aria-label="Store">
                 <FaShoppingCart/>
                    </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to='mint' onClick={toggle} smooth={true} duration={500} spy={true} exact='true' offset={-70} >Mint Page</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>

        </SidebarContainer>
    )
}

export default Sidebar
