import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import styled from "styled-components";
import pink from "../../images/pc.gif";
import Select from 'react-select';

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  padding: 10px;
  font-weight: bold;
  width: 180px;
background:pink;
white-space:nowrap;
color: #010606;
font-size: 22px;
outline:none;
border:none;
cursor:pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
 font-family: "coder";
src: local("coder"),
  url(../assets/fonts/lunaland.woff) format("woff");


&:hover{
    transition: 0.2s ease-in-out;
    background: #fff;
    color: #010606;
}
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;

  @media (min-width: 767px) {
    flex-direction: row;

  }
`;

export const StyledImg = styled.img`
  width: 280px;
  height: 280px;
  border-radius:50px;
  @media (min-width: 767px) {
    width: 350px;
    height: 350px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function InfoSection() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [amount, setAmount] = useState('1');
  const options = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ];

  const claimNFTs = (_amount) => {
    setClaimingNft(true);
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your Pink Cat NFT...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(_amount)
      .send({
        gasLimit: (250000).toString(),
        to: "0x119Ed1d06D549a388e71233EaD8c79F855eF1fA0",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.069 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log("mint error");
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log("mint success");
        setFeedback(
          "Thank you for minting!"
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  function getMintComponent() {
      return(
        <>
          <s.TextDescription style={{ textAlign: "center",color:'black'}}>
            cost: 0.069 ETH
          </s.TextDescription >
          <s.TextDescription style={{ textAlign: "center",color:'black'}}>
            {feedback}
          </s.TextDescription >
          <s.SpacerMedium />
          <s.Container ai={"center"} jc={"center"} fd={"row"}>
            <StyledButton
              style={{ marginRight: 10}}
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                claimNFTs(parseInt(amount));
                getData();
              }}
            >
              {claimingNft ? "BUSY" : "MINT"}
            </StyledButton>
            <Select
              defaultValue={amount}
              onChange={(amount) => { console.log(amount); setAmount(amount['value'])}}
              options={options}
              style={{ marginLeft: 10}}
            />
          </s.Container>
        </>
      );
  }

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen id='mint' style={{ backgroundColor: "#F0FFFF" }}>
      <s.Container  flex={1} ai={"center"} style={{ padding: 24 }}>
        <s.TextTitle style={{  textAlign: "center", fontSize: 40, fontWeight: "bold", color: 'black' }}>Pink Cat NFT</s.TextTitle>
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center" }>
            <StyledImg alt={"example"} src={pink}/>
            <s.SpacerMedium />
            <s.TextTitle style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}>
              {data.totalSupply}/8888
            </s.TextTitle>
          </s.Container>
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{ backgroundColor: "#F0FFFF", padding: 24 }}
          >
            {Number(data.totalSupply) === 8888? (
              <>
                <s.TextTitle style={{ textAlign: "center", color:'black' }}>
                  The claim has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center",color:'black'  }}>
                  You can still find Pink Cat NFT on{" "}
                  <a
                    target={"_blank"}
                    href={"https://opensea.io/collection/"}
                  >
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center",color:'black'}}>
                  Pink Cat Public Sale Live at 11:11 AM PST!
                </s.TextTitle>
                <s.SpacerXSmall />
                { blockchain.account === "" || blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : getMintComponent(blockchain.account)
                }
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "50%" } }>
          <s.TextDescription style={{ textAlign: "center", fontSize: 9 ,color:'black' }}>
            Please make sure you are connected to Ethereum Mainnet and are using the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </s.TextDescription>
          {/* <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center", fontSize: 9 , color:'black' }}>
            We have set the gas limit to 285000 for the contract to successfully
            mint your NFT. We recommend that you don't change the gas limit.
          </s.TextDescription> */}
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default InfoSection;
